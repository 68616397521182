<template>
  <div>
    <!-- input search -->
    <div class="d-flex ">
      <b-col md="3">
        <b-form-group
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="Parcour"
            label="title"
            :options="parcours"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Groups"
        >
          <v-select
            v-model="group"
            :clearable="false"
            placeholder="Groups"
            label="group_code"
            :options="groups"
          />
        </b-form-group>
      </b-col>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="students"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'presence'">
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-select
                  v-model="props.row.presence"
                  :options="['Présent', 'Absent', 'Dispensé']"
                  class="mx-1"
                  @change="setAbsence(props.row.student.id, props.row.group.id, subjectId ,props.row.presence)"
                />
              </div>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">
            <b-button
              variant="primary"
              type="submit"
              class="d-flex align-items-center"
              @click.prevent="addPresences"
            >
              Enregistrer les Absences
            </b-button>
          </span>

        </div>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BButton, BFormGroup, BFormSelect, BCol,
  BSpinner,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import store from '@/store/index'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    // BButton,
    BSpinner,
    vSelect,
    BCol,
    BButton,
    // BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      user: {},
      columns: [
        {
          label: 'N°',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },

        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Présence',
          field: 'presence',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher telephone',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
      ],
      attendances: [],
      presenceStudents: [],
      parcours: [],
      students: [],
      groups: [],
      parcour: {},
      group: {},
      presences: [],
      types: ['Présent', 'Absent', 'Dispensé'],
      searchTerm: '',
    }
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    groupe() {
      // eslint-disable-next-line array-callback-return
      return this.groupes.filter(groupe => groupe.parcour.id === this.selectedparcours.id)
    },
  },
  created() {
    this.getAttendance()
    this.getParcours()
    this.getGroupStudent()
    this.getGroup()
    this.date = this.currentDate()
    this.time = this.currentTime()
    this.user = storeAuth.state.user
    this.subjectId = this.$route.params.subjectId
  },
  methods: {
    setAbsence(id, groupId, subjectId, presence) {
      const today = new Date()
      const current = new Date()
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const item = {
        id,
        groupId,
        subjectId,
        presence,
        date,
        time,
      }
      const check = this.presenceStudents.find(student => student.id === item.id)
      const index = this.presenceStudents.findIndex(student => student.id === item.id)
      if (check === undefined) {
        this.presenceStudents.push(item)
      } else {
        this.presenceStudents.splice(index, 1, item)
      }
    },

    async addPresences() {
      try {
        await axios
          .post(
            '/api/attendance/affect-presence/',
            {
              presences: this.presenceStudents,
            },
          )
        this.$router.push('/presences')
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Absence ajouté avec succés')
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', err.toString())
        }, 1000)
      }
    },
    async absent(idUser, event) {
      try {
        await axios
          .post(
            '/api/attendance/create/',
            {
              student: idUser,
              date: this.date,
              time: this.time,
              presence: event,
            },
          )
        this.$router.push('/presences')
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Absence ajouté avec succés')
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', err.toString())
        }, 1000)
      }
    },

    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getGroupStudent() {
      const response = await axios.get('/api/inscriptions/', {
        params: {
          group_id: this.$route.params.id,
        },
      })
      this.students = response.data
    },
    async getGroup() {
      const response = await axios.get('/api/groups/')
      this.groups = response.data
    },
    // async AddPresence(id) {
    //   try {
    //     await axios
    //       .post(
    //         '/api/attendance/create/',
    //         {
    //           student: id,
    //           date: this.date,
    //           time: this.time,
    //           presence: this.presence,
    //         },
    //       )
    //     this.$router.push('/presences')
    //     setTimeout(() => {
    //       this.showToast('success', 'top-center', 'Absence ajouté avec succés')
    //     }, 1000)
    //   } catch (err) {
    //     setTimeout(() => {
    //       this.showToast('danger', 'top-center', err.toString())
    //     }, 1000)
    //   }
    // },
    async getAttendance() {
      this.load = 'true'
      const response = await axios.get('api/attendance/')
      this.attendances = response.data
      this.load = 'false'
    },

    deleteAttendance(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/auth/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getAttendance()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Voiture supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Voiture Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    currentDate() {
      const current = new Date()
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      return date
    },
    currentTime() {
      const today = new Date()
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      return time
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
